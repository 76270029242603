import React, { useState, useEffect } from "react";
import styles from "../Css/home.module.css";
import Ncash_logo from "../Images/Ncash_logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../index.css";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useNavigate, useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import axios from "axios";
import { setAccessToken } from "../Redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchDataCount } from "../Redux/apiGetSlice";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = process.env.REACT_APP_API_PATH;

const HomePage = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  // const accessToken = localStorage.getItem("token");
  const loginResponse = useSelector((state) => state.auth.loginResponse);
  // console.log("Test Redux", apiData?.data);
  const [count, setCount] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    all: 0,
  });

  // const data = useSelector((state) => state.fetchData.data);
  // const loading = useSelector((state) => state.fetchData.loading);
  // const error = useSelector((state) => state.fetchData.error);
  // const dataCount = useSelector((state) => state.fetchDataCount.data);
  // const loadingCount = useSelector((state) => state.fetchDataCount.loading);
  // const errorCount = useSelector((state) => state.fetchDataCount.error);
  const [loading, setLoading] = useState(true);
  const [tblData, setTblData] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [navbar, setNavbar] = useState("APPROVER_PENDING");
  const name = "John Doe";
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = tblData ?? [].slice(startIndex, endIndex);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "client-id": process.env.REACT_APP_CLIENT_ID,
    "client-secret": process.env.REACT_APP_CLIENT_SECRET,
  };
  const requestBody = {
    status: navbar === "ALL" ? null : navbar,
    from: null,
    to: null,
    pn: 0,
    rc: 0,
  };

  const requestBodycount = {
    from: null,
    to: null,
  };

  // console.log("test", config);
  useEffect(() => {
    // dispatch(
    //   fetchData({
    //     url: `${baseUrl}/secure/detax/card/claims/approver`,
    //     headers,
    //     params: requestBody,
    //   })
    // );
    // dispatch(
    //   fetchDataCount({
    //     url: `${baseUrl}/secure/detax/card/claims/status/count/approver`,
    //     headers,
    //     params: requestBodycount,
    //   })
    // );
    // setTblData(data.data);
    // console.log(error);
    setLoading(true);
    axios
      .get(`${baseUrl}/secure/detax/card/claims/approver`, {
        ...config,
        params: requestBody,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Success Table");
          setTblData(response.data.data);
          setLoading(false);
        } else {
          console.log(response.status);
        }
      })
      .catch((error) => {
        console.log("Failed", error);
      });

    // Api for status count
    axios
      .get(`${baseUrl}/secure/detax/card/claims/status/count/approver`, {
        ...config,
        params: requestBodycount,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Success Count", response);
          // setCount(response.data);
          let counts = response.data.data;
          const updatedCount = {
            pending: 0,
            approved: 0,
            rejected: 0,
            all: 0,
          };
          counts.map((item) => {
            const { status, count } = item;
            if (status === "APPROVER_PENDING") {
              updatedCount.pending = count;
            } else if (status === "APPROVED") {
              updatedCount.approved = count;
            } else if (status === "REJECTED") {
              updatedCount.rejected = count;
            }
            updatedCount.all += count;
          });
          setCount(updatedCount);
        } else {
          console.log(response.status);
        }
      })
      .catch((error) => {
        console.log("Failed", error);
      });
  }, [navbar, accessToken]);

  const handleTimeRemove = (data) => {
    const dateTimeString = data;
    const dateParts = dateTimeString.split(" ");
    const dateOnly = dateParts[0];
    return dateOnly;
  };

  console.log("Test Count", count);
  return (
    <div className={styles.main}>
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "70%"}
              height={matches ? "80%" : "70%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 17,
              fontWeight: "500",
            }}
          >
            Claims Approval
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "60%" : "50%",
                width: matches ? "20%" : "50%",
              }}
            />
          </div>
        </div>
        <div
          className={styles.namecon}
          style={{
            fontSize: matches ? 23 : 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            Hello {loginResponse.full_name},
            <div style={{ fontSize: "17px" }}>
              The following are the list of claims{" "}
              {navbar === "APPROVER_PENDING"
                ? "pending your approval."
                : navbar === "APPROVED"
                ? "approved."
                : "rejected."}
            </div>
          </div>

          <div>
            <Button
              endIcon={<LogoutIcon />}
              sx={{
                color: "#0D519A",
                fontStyle: "bold",
                fontSize: "15px",
              }}
              onClick={() => {
                // navigate("/");
                dispatch(setAccessToken(null));
              }}
            >
              {matches ? "Logout" : ""}
            </Button>
          </div>
        </div>
        <div className={styles.filtercon}>
          <div
            className={styles.navbar}
            style={{
              borderBottom:
                navbar === "APPROVER_PENDING" ? "2px solid #0B5298" : "",
              paddingBottom: 3,
              fontSize: matches ? 20 : 18,
            }}
            onClick={() => {
              setNavbar("APPROVER_PENDING");
            }}
          >
            Pending<div className={styles.navbarcount}>{count.pending}</div>
          </div>
          <div
            className={styles.navbar}
            style={{
              borderBottom: navbar === "APPROVED" ? "2px solid #0B5298" : "",
              paddingBottom: 3,
              fontSize: matches ? 20 : 18,
            }}
            onClick={() => {
              setNavbar("APPROVED");
            }}
          >
            Approved<div className={styles.navbarcount}>{count.approved}</div>
          </div>
          <div
            className={styles.navbar}
            style={{
              borderBottom: navbar === "REJECTED" ? "2px solid #0B5298" : "",
              paddingBottom: 3,
              fontSize: matches ? 20 : 18,
            }}
            onClick={() => {
              setNavbar("REJECTED");
            }}
          >
            Rejected<div className={styles.navbarcount}>{count.rejected}</div>
          </div>
          {/* <div
            className={styles.navbar}
            style={{
              borderBottom: navbar === "ALL" ? "2px solid #0B5298" : "",
              paddingBottom: 3,
              fontSize: matches ? 20 : 18,
            }}
            onClick={() => {
              setNavbar("ALL");
            }}
          >
            All<div className={styles.navbarcount}>{count.all}</div>
          </div> */}
        </div>
        {loading && (
          <div style={{ paddingTop: "5%" }}>
            <CircularProgress />
          </div>
        )}

        {paginatedData.length > 0 && !loading ? (
          <div className={styles.tablecon}>
            <div style={{ height: matches ? 450 : null }}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    {matches ? <th>S.No.</th> : null}
                    <th>Name</th>
                    <th>Claim Date</th>
                    <th>Claim Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((items, index) => (
                    <tr
                      onClick={() =>
                        matches
                          ? navigate("/claim" + "/" + items.claimId)
                          : null
                      }
                      className={styles.tr2}
                    >
                      {matches ? <td>{startIndex + index + 1}</td> : null}
                      {matches ? (
                        <td>{items.initiator.name}</td>
                      ) : (
                        <td
                          onClick={() =>
                            navigate("/claim" + "/" + items.claimId)
                          }
                          style={{
                            color: "#0d6efd",
                            display: "flex",
                            textAlign: "left",
                          }}
                        >
                          <u>{items.initiator.name}</u>
                        </td>
                      )}
                      <td>{handleTimeRemove(items.initiatedAt)}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: matches ? "center" : "flex-end",
                        }}
                      >
                        ₹ {items.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationWrapper}>
              <Pagination
                current={currentPage}
                total={tblData.length}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 10,
                fontWeight: "700",
                justifyContent: "center",
                paddingTop: matches ? "2%" : "10%",
                paddingBottom: matches ? "2%" : "5%",
              }}
            >
              Powered by Figital Technologies
            </div>
          </div>
        ) : (
          <img
            hidden={loading}
            src={require("../Images/No-data-found.png")}
            style={{
              height: matches ? "40%" : "40%",
              width: matches ? "35%" : "50%",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HomePage;

const countData = [
  {
    status: "PENDING",
    label: "Initiated",
    count: 0,
  },
  {
    status: "APPROVER_PENDING",
    label: "Approver Pending",
    count: 0,
  },
  {
    status: "REVIEW_PENDING",
    label: "Under Review",
    count: 0,
  },
  {
    status: "APPROVED",
    label: "Approver Pending",
    count: 0,
  },
  {
    status: "REJECTED",
    label: "Rejected",
    count: 0,
  },
  {
    status: "IN_PROCESS",
    label: "Reimbursement IP",
    count: 0,
  },
  {
    status: "REIMBURSED",
    label: "Reimbursed",
    count: 0,
  },
];

const DummyData = [
  {
    name: "John Doe",
    amount: "2000",
    date: "23/03/2023",
    mobile: "9876543210",
    email: "a@gmail.com",
    claimId: "CLM-2849",
    category: "Food",
  },
  {
    name: "Jane Doe",
    amount: "1500",
    date: "24/03/2023",
    mobile: "1234567890",
    email: "b@gmail.com",
    claimId: "CLM-2850",
    category: "Travel",
  },
  {
    name: "Bob Smith",
    amount: "3000",
    date: "25/03/2023",
    mobile: "4567890123",
    email: "c@gmail.com",
    claimId: "CLM-2851",
    category: "Shopping",
  },
  {
    name: "Alice Johnson",
    amount: "2500",
    date: "26/03/2023",
    mobile: "7890123456",
    email: "d@gmail.com",
    claimId: "CLM-2852",
    category: "Health",
  },
  {
    name: "Samantha Lee",
    amount: "2200",
    date: "27/03/2023",
    mobile: "2345678901",
    email: "e@gmail.com",
    claimId: "CLM-2853",
    category: "Food",
  },
  {
    name: "Chris Brown",
    amount: "1800",
    date: "28/03/2023",
    mobile: "5678901234",
    email: "f@gmail.com",
    claimId: "CLM-2854",
    category: "Travel",
  },
  {
    name: "Emma Watson",
    amount: "1900",
    date: "29/03/2023",
    mobile: "9012345678",
    email: "g@gmail.com",
    claimId: "CLM-2855",
    category: "Shopping",
  },
  {
    name: "James Wilson",
    amount: "2700",
    date: "30/03/2023",
    mobile: "3456789012",
    email: "h@gmail.com",
    claimId: "CLM-2856",
    category: "Health",
  },
  {
    name: "Olivia Davis",
    amount: "3200",
    date: "31/03/2023",
    mobile: "6789012345",
    email: "i@gmail.com",
    claimId: "CLM-2857",
    category: "Food",
  },
  {
    name: "Liam Taylor",
    amount: "4000",
    date: "01/04/2023",
    mobile: "0123456789",
    email: "j@gmail.com",
    claimId: "CLM-2858",
    category: "Travel",
  },
  {
    name: "Noah Anderson",
    amount: "1500",
    date: "02/04/2023",
    mobile: "3456789012",
    email: "k@gmail.com",
    claimId: "CLM-2859",
    category: "Shopping",
  },
  {
    name: "Alice",
    amount: "1500",
    date: "02/04/2023",
    mobile: "3456789012",
    email: "l@gmail.com",
    claimId: "CLM-2860",
    category: "Shopping",
  },
];
