import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {
  let auth = localStorage.getItem("auth");
  if (isAuthenticated) {
    return <>{children}</>;
  }
  return <Navigate to="/" replace />;
};

export default ProtectedRoute;
