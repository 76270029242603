import { TextField, Icon } from "@mui/material";
import React, { useState } from "react";
import styles from "../Css/login.module.css";
import Ncash_logo from "../Images/Ncash_logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Figital from "../Images/bg1-new.svg";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import * as yup from "yup";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setAccessToken, setLoginResponse } from "../Redux/authSlice";

const emailRegExp = new RegExp("[A-Za-z0-9]+@[A-Za-z]+.[A-Za-z]{2,3}");
const schema = yup.object().shape({
  username: yup
    .string()
    .matches(emailRegExp, "Email is not valid")
    .required("Email is required"),
  password: yup.string().required("Please enter your password"),
});
const baseUrl = process.env.REACT_APP_API_PATH;

const LoginPage = () => {
  const dispatch = useDispatch(); //Redux
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState(false);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [errmsg, setErrmsg] = useState("");

  const requestBody = {
    username: username,
    password: password,
    grant_type: "password",
  };
  // const requestBody = {
  //   username: "sathis.raj@ncash.ai",
  //   password: "Figital@321",
  //   grant_type: "password",
  // };
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic " + process.env.REACT_APP_CLIENT,
    },
  };
  const onLoginClick = () => {
    setErrmsg("");
    schema
      .validate({ username, password }, { abortEarly: false })
      .then(() => {
        // validation succeeded, submit the form
        axios
          .post(`${baseUrl}/oauth/login`, requestBody, config)
          .then((response) => {
            if (response.status === 200) {
              // Redux
              dispatch(setAccessToken(response.data.access_token));
              dispatch(setLoginResponse(response.data));
              console.log(response);
              console.log("Success");
              if (response.data.initPassChanged === true) {
                navigate("/home");
              } else {
                navigate("/change-password");
              }
            } else {
              console.log(response.data.message);
              setLoad(false);
            }
          })
          .catch((error) => {
            console.log("Failed", error.response);
            setErrmsg(error.response.data.message);
            setLoad(false);
          });

        console.log("TESTING");
      })
      .catch((err) => {
        // validation failed, handle the error
        const newErrors = {};
        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
        setLoad(false);
      });
  };
  // console.log("Test", errors);
  return (
    <div className={styles.main}>
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "50%"}
              height={matches ? "60%" : "50%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 14,
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {matches ? "" : "Claims Approver Dashboard"}
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "40%" : "40%",
                width: matches ? "35%" : "50%",
              }}
            />
          </div>
        </div>
        {matches ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <img src={Figital} style={{ height: "70%", width: "80%" }} />
          </div>
        )}
        <div className={styles.subcon}>
          {matches ? (
            <div className={styles.leftcon}>
              <div>
                <div className={styles.gettxt}>
                  Claims Approver
                  <br />
                  Dashboard
                </div>
              </div>
              <div className={styles.imgcon}>
                <img src={Figital} style={{ height: "80%", width: "80%" }} />
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "25%" }}></div>
          )}
          <div className={styles.rightcon}>
            <div className={styles.shadow}>
              <div className={styles.logincon}>
                <div
                  style={{ color: "#0B5298", fontSize: 30, fontWeight: 600 }}
                >
                  Log In
                </div>
              </div>
              <div className={styles.plzcon}>
                <div style={{ fontSize: 16, fontWeight: 600 }}>
                  Please enter your credentials
                </div>
              </div>
              <div className={styles.midrcon}>
                <div className={styles.field}>
                  <TextField
                    type={"email"}
                    required
                    id="outlined-required"
                    label="Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={Boolean(errors.username)}
                    helperText={errors.username}
                    // size="medium"
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordType === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordType(!passwordType)}
                          >
                            {passwordType === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.forgotcon}>
                  <div
                    className={styles.forgotpassword}
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    Forgot Password ?
                  </div>
                </div>
                <div className={styles.btncon}>
                  <LoadingButton
                    // loading={load}
                    className={styles.btn}
                    variant="contained"
                    sx={{ color: "#0D519A", borderColor: "#0D519A" }}
                    style={{ color: "#fff", width: 150, height: 40 }}
                    // type="submit"
                    // onClick={() => {
                    //   onLoginClick();
                    //   setLoad(true);
                    // }}
                    // onClick={() => navigate("/home")}
                    onClick={() => onLoginClick()}
                  >
                    Login
                  </LoadingButton>
                  <div>
                    {errmsg !== "" ? (
                      <div style={{ paddingTop: "15%", color: "#FF4343" }}>
                        {errmsg}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
