import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { setAccessToken } from "./Components/Redux/authSlice";
import Modal from "react-modal";
import { LoadingButton } from "@mui/lab";
const customStyles = {
  content: {
    width: "400px", // Set the width of the modal
    height: "240px", // Set the height of the modal
    margin: "auto", // Center the modal horizontally
    display: "flex",
    alignItems: "center", // Center the modal vertically
    justifyContent: "center",
    borderRadius: "20px",
  },
};
export default function IdleTimeOut() {
  const dispatch = useDispatch();
  // Set timeout values
  const timeout = 1000 * 60 * 5; // logout prompt will appears in seconds
  const promptTimeout = 1000 * 60 * 5; // 5 mins

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const handleLogout = async () => {
    dispatch(setAccessToken(null));
  };

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 300 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);
    handleLogout();
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    events: ["mousemove", "keydown"],
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      <Modal isOpen={open} style={customStyles}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Session Timeout</h2>
          <p>Logging you out in {remaining} due to inactivity.</p>

          <LoadingButton
            // loading={load}
            // className={styles.btn}
            variant="contained"
            sx={{ color: "#0D519A", borderColor: "#0D519A" }}
            style={{
              color: "#fff",
              width: 150,
              height: 40,
              backgroundImage: "linear-gradient(90deg, #8cd4ed, #105099)",
              marginTop: "20px",
            }}
            onClick={handleStillHere}
          >
            I am Still Here
          </LoadingButton>
        </div>
      </Modal>
    </>
  );
}
