import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authSlice";
import {
  fetchClaimDetailsaReducer,
  fetchDataCountReducer,
  fetchDataReducer,
} from "./apiGetSlice";
import { updateClaimStatusReducer } from "./apiPutSlice";
import { postSetPasswordReducer } from "./apiPostSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedfetchDataReducer = persistReducer(
  persistConfig,
  fetchDataReducer
);
const persistedfetchDataCountReducer = persistReducer(
  persistConfig,
  fetchDataCountReducer
);
const persistedfetchClaimDetailsReducer = persistReducer(
  persistConfig,
  fetchClaimDetailsaReducer
);
const persistedupdateClaimStatusReducer = persistReducer(
  persistConfig,
  updateClaimStatusReducer
);
const persistedpostSetPasswordReducer = persistReducer(
  persistConfig,
  postSetPasswordReducer
);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    fetchData: persistedfetchDataReducer,
    fetchDataCount: persistedfetchDataCountReducer,
    fetchClaimDetails: persistedfetchClaimDetailsReducer,
    updateClaimStatus: persistedupdateClaimStatusReducer,
    postSetPassword: persistedpostSetPasswordReducer,
    // other reducers if any
  },
});

export const persistor = persistStore(store);
