import logo from "./logo.svg";
import "./App.css";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import LoginPage from "./Components/Screens/LoginPage";
import HomePage from "./Components/Screens/HomePage";
import ClaimsPage from "./Components/Screens/ClaimsPage";
import ForgotPasswordPage from "./Components/Screens/ForgotPasswordPage";
import ChangePasswordPage from "./Components/Screens/ChangePasswordPage";
import ProtectedRoute from "./protected-route";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import IdleTimeOut from "./IdleTimeout";

function App() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isAuthenticated, setIsAuthenticated] = useState(accessToken !== null);
  useEffect(() => {
    setIsAuthenticated(accessToken !== null);
  }, [accessToken]);

  console.log("App.js", isAuthenticated);

  return (
    <>
      {isAuthenticated && <IdleTimeOut />}
      <Routes>
        {/* Redirect non-existing routes to the home page */}
        <Route
          path="*"
          element={
            isAuthenticated ? <Navigate to="/home" /> : <Navigate to="/" />
          }
        />

        <Route path="/" element={<LoginPage />} />

        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/claim/:claimId"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ClaimsPage />
            </ProtectedRoute>
          }
        />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
      </Routes>
    </>
  );
}

export default App;
