import { TextField, Icon } from "@mui/material";
import React, { useState } from "react";
import styles from "../Css/changepassword.module.css";
import Ncash_logo from "../Images/Ncash_logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Figital from "../Images/bg1-new.svg";
import ResetPassword from "../Images/reset_password.svg";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAccessToken, setLoginResponse } from "../Redux/authSlice";

const baseUrl = process.env.REACT_APP_API_PATH;
const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const [passwordCur, setPasswordCur] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordCon, setPasswordCon] = useState("");
  const [passwordTypeCur, setPasswordTypeCur] = useState(false);
  const [passwordTypeNew, setPasswordTypeNew] = useState(false);
  const [passwordTypeCon, setPasswordTypeCon] = useState(false);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [errmsg, setErrmsg] = useState("");
  const requestBody = {
    password: passwordCur,
    newPassword: passwordCon,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  const changePassword = () => {
    axios
      .post(`${baseUrl}/oauth/secure/change/password`, requestBody, config)
      .then((response) => {
        if (response.status === 200) {
          // Redux

          console.log(response);
          console.log("Success");
          navigate("/");
        } else {
          console.log(response.data.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Failed", error.response);
        setErrmsg(error.response.data.message);
        setLoad(false);
      });
  };
  return (
    <div className={styles.main}>
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "50%"}
              height={matches ? "60%" : "50%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 14,
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {matches ? "" : "Claims Approver Dashboard"}
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "40%" : "40%",
                width: matches ? "35%" : "50%",
              }}
            />
          </div>
        </div>
        {matches ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <img src={ResetPassword} style={{ height: "70%", width: "80%" }} />
          </div>
        )}
        <div className={styles.subcon}>
          {matches ? (
            <div className={styles.leftcon}>
              <div>
                {/* <div className={styles.gettxt}>
                  Claims Approver
                  <br />
                  Dashboard
                </div> */}
              </div>
              <div className={styles.imgcon}>
                <img
                  src={ResetPassword}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "25%" }}></div>
          )}
          <div className={styles.rightcon}>
            <div className={styles.shadow}>
              <div className={styles.logincon}>
                <div
                  style={{ color: "#0B5298", fontSize: 30, fontWeight: 600 }}
                >
                  Change Password
                </div>
              </div>
              {/* <div className={styles.plzcon}>
                <div style={{ fontSize: 16, fontWeight: 600 }}>
                  Please enter your email
                </div>
              </div> */}
              <div className={styles.midrcon}>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Current Password"
                    value={passwordCur}
                    onChange={(e) => setPasswordCur(e.target.value)}
                    // error={Boolean(errors.password)}
                    // helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordTypeCur === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordTypeCur(!passwordTypeCur)}
                          >
                            {passwordTypeCur === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="New Password"
                    value={passwordNew}
                    onChange={(e) => setPasswordNew(e.target.value)}
                    // error={Boolean(errors.password)}
                    // helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordTypeNew === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordTypeNew(!passwordTypeNew)}
                          >
                            {passwordTypeNew === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Confirm Password"
                    value={passwordCon}
                    onChange={(e) => setPasswordCon(e.target.value)}
                    // error={Boolean(errors.password)}
                    // helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordTypeCon === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordTypeCon(!passwordTypeCon)}
                          >
                            {passwordTypeCon === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className={styles.btncon}>
                  <LoadingButton
                    // loading={load}
                    disabled={
                      passwordCur.length === 0 ||
                      passwordCon !== passwordNew ||
                      passwordCon.length === 0
                    }
                    // className={styles.btn}
                    variant="contained"
                    sx={{ color: "#0D519A", borderColor: "#0D519A" }}
                    style={{ color: "#fff", width: 200, height: 40 }}
                    // type="submit"
                    onClick={() => changePassword()}
                  >
                    Change Password
                  </LoadingButton>
                  <div>
                    {errmsg !== "" ? (
                      <div style={{ paddingTop: "15%", color: "#FF4343" }}>
                        {errmsg}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <div className={styles.backtologin}>
                  Back to
                  <div className={styles.login} onClick={() => navigate("/")}>
                    Login
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
