import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk for making the first API request
export const fetchData = createAsyncThunk(
  "api/fetchData",
  async ({ url, headers, params }) => {
    const response = await axios.get(url, { headers, params });
    return response.data;
  }
);

// Define the async thunk for making the second API request
export const fetchDataCount = createAsyncThunk(
  "api/fetchDataCount",
  async ({ url, headers, params }) => {
    const response = await axios.get(url, { headers, params });
    return response.data;
  }
);

export const fetchClaimDetails = createAsyncThunk(
  "api/fetchClaimDetails",
  async ({ url, headers, params }) => {
    const response = await axios.get(url, { headers, params });
    return response.data;
  }
);
// Create a separate slice for the first API
const fetchDataSlice = createSlice({
  name: "fetchData",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Create a separate slice for the second API
const fetchDataCountSlice = createSlice({
  name: "fetchDataCount",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataCount.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const fetchClaimDetailsSlice = createSlice({
  name: "fetchClaimDetails",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClaimDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchClaimDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
// Export the reducers for both API slices
export const fetchDataReducer = fetchDataSlice.reducer;
export const fetchDataCountReducer = fetchDataCountSlice.reducer;
export const fetchClaimDetailsaReducer = fetchClaimDetailsSlice.reducer;
