import { TextField, Icon } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import styles from "../Css/forgotpassword.module.css";
import Ncash_logo from "../Images/Ncash_logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Figital from "../Images/bg1-new.svg";
import ForgotPassword from "../Images/forgot_password.svg";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_PATH;

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [screen, setScreen] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [otpId, setOtpId] = useState("");
  const [load, setLoad] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [emailError, setEmailError] = useState("");
  const requestBody = {
    username: username,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  const requestOtp = () => {
    axios
      .post(`${baseUrl}/oauth/forgot/password/sendOtp`, requestBody, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.successful === true) {
            console.log(response);
            console.log("Success");
            setOtpId(response.data.otpId);
            setScreen(1);
          } else {
            setErrmsg(response.data.message);
          }
          // Redux
        } else {
          console.log(response.data.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Failed", error.response);
        setErrmsg(error.response.data.message);
        setLoad(false);
      });
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setEmailError(validateEmail(value));
  };

  const validateEmail = (value) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      return "Invalid email format";
    }
    return "";
  };

  return (
    <div className={styles.main}>
      {screen === 0 ? (
        <div className={styles.submain}>
          <div className={styles.header}>
            <div
              style={{
                width: "33.3%",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                //  paddingRight: "30%",
              }}
            >
              <Ncash_logo
                width={matches ? "20%" : "50%"}
                height={matches ? "60%" : "50%"}
              />
            </div>
            <div
              className={styles.detaxtxt}
              style={{
                width: "33.3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: matches ? 30 : 14,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {matches ? "" : "Claims Approver Dashboard"}
            </div>
            <div
              style={{
                width: "33.3%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <img
                src={require("../Images/Figital_logo.png")}
                style={{
                  height: matches ? "40%" : "40%",
                  width: matches ? "35%" : "50%",
                }}
              />
            </div>
          </div>
          {matches ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "2%",
                paddingBottom: "2%",
              }}
            >
              <img
                src={ForgotPassword}
                style={{ height: "70%", width: "80%" }}
              />
            </div>
          )}
          <div className={styles.subcon}>
            {matches ? (
              <div className={styles.leftcon}>
                <div>
                  {/* <div className={styles.gettxt}>
                  Claims Approver
                  <br />
                  Dashboard
                </div> */}
                </div>
                <div className={styles.imgcon}>
                  <img
                    src={ForgotPassword}
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ paddingLeft: "25%" }}></div>
            )}
            <div className={styles.rightcon}>
              <div className={styles.shadow}>
                <div className={styles.logincon}>
                  <div
                    style={{ color: "#0B5298", fontSize: 30, fontWeight: 600 }}
                  >
                    Forgot Password
                  </div>
                </div>
                <div className={styles.plzcon}>
                  <div style={{ fontSize: 16, fontWeight: 600 }}>
                    Please enter your email
                  </div>
                </div>
                <div className={styles.midrcon}>
                  <div className={styles.field}>
                    <TextField
                      type={"email"}
                      required
                      id="outlined-required"
                      label="Email"
                      value={username}
                      onChange={handleEmailChange}
                      error={Boolean(emailError)}
                      helperText={emailError}
                      inputProps={{
                        style: {
                          height: "15px",
                          width: "230px",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div
                    className={styles.btncon}
                    style={{ flexDirection: "column" }}
                  >
                    <LoadingButton
                      // loading={load}
                      // className={styles.btn}
                      disabled={Boolean(emailError) || username.length === 0}
                      variant="contained"
                      sx={{ color: "#0D519A", borderColor: "#0D519A" }}
                      style={{ color: "#fff", width: 150, height: 40 }}
                      // type="submit"
                      // onClick={() => {
                      //   onLoginClick();
                      //   setLoad(true);
                      // }}
                      onClick={() => requestOtp()}
                    >
                      Send OTP
                    </LoadingButton>
                    <div>
                      {errmsg !== "" ? (
                        <div style={{ paddingTop: "15%", color: "#FF4343" }}>
                          {errmsg}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.backtologin}>
                    Back to
                    <div className={styles.login} onClick={() => navigate("/")}>
                      Login
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : screen === 1 ? (
        <OtpPage otpId={otpId} setScreen={setScreen} requestOtp={requestOtp} />
      ) : (
        <ResetPasswordPage otpId={otpId} setScreen={setScreen} />
      )}
    </div>
  );
};

export default ForgotPasswordPage;

const OtpPage = ({ otpId, setScreen, requestOtp }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otperr, setOtperr] = useState(false);
  const otpValue = otp.join("");

  const inputRefs = useRef([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [otpCounter, setOtpCounter] = useState(25);
  const timerSeconds = (num) => {
    if (num === 0) return "";
    else {
      let str = num + "";
      if (str.length === 1) str = "0" + num;
      return "00:" + str;
    }
  };
  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);

  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [errmsg, setErrmsg] = useState("");

  const handleChange = (e, index) => {
    const value = e.target.value;
    setOtp((prev) => {
      const otpCopy = [...prev];
      otpCopy[index] = value;
      return otpCopy;
    });

    // Focus next input field when current input is filled
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Remove value from previous input field when backspace is pressed in empty input
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
      setOtp((prev) => {
        const otpCopy = [...prev];
        otpCopy[index - 1] = "";
        return otpCopy;
      });
      setOtperr(false);
    }
  };
  const requestBody = {
    otp: otpValue,
    otpId: otpId,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  const submitOtp = () => {
    axios
      .post(`${baseUrl}/oauth/forgot/password/verify/otp`, requestBody, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.successful === true) {
            console.log(response);
            console.log("Success");
            setScreen(2);
          } else {
            setErrmsg(response.data.message);
          }
          // Redux
        } else {
          console.log(response.data.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Failed", error.response);
        setErrmsg(error.response.data.message);
        setLoad(false);
      });
  };
  return (
    <div className={styles.main}>
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "50%"}
              height={matches ? "60%" : "50%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 14,
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {matches ? "" : "Claims Approver Dashboard"}
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "40%" : "40%",
                width: matches ? "35%" : "50%",
              }}
            />
          </div>
        </div>
        {matches ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <img src={ForgotPassword} style={{ height: "70%", width: "80%" }} />
          </div>
        )}
        <div className={styles.subcon}>
          {matches ? (
            <div className={styles.leftcon}>
              <div>
                {/* <div className={styles.gettxt}>
                  Claims Approver
                  <br />
                  Dashboard
                </div> */}
              </div>
              <div className={styles.imgcon}>
                <img
                  src={ForgotPassword}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "25%" }}></div>
          )}
          <div className={styles.rightcon}>
            <div className={styles.shadow}>
              <div className={styles.logincon}>
                <div
                  style={{ color: "#0B5298", fontSize: 30, fontWeight: 600 }}
                >
                  OTP Verification
                </div>
              </div>
              <div className={styles.plzcon}>
                <div
                  style={{ fontSize: 16, fontWeight: 600, textAlign: "center" }}
                >
                  We have sent the verification code to your
                  <br /> mobile number
                </div>
              </div>
              <div className={styles.midrcon}>
                <div
                  className={styles.field}
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {otp.map((value, index) => (
                      <input
                        className={styles.inputfield}
                        // style={{
                        //   borderBottomColor: otperr ? "#ff0000" : "black",
                        // }}
                        key={index}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={styles.backtologin}
                  style={{ flexDirection: "column" }}
                >
                  Did not receive OTP?{" "}
                  <button
                    style={{
                      color: "#14559c",
                      border: 0,
                      backgroundColor: "transparent",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      requestOtp();
                      setOtpCounter(25);
                    }}
                    disabled={otpCounter !== 0}
                  >
                    Resend Code{" "}
                    <span style={{ color: "#dc3545" }}>
                      {timerSeconds(otpCounter)}
                    </span>
                  </button>
                </div>
                <div
                  className={styles.btncon}
                  style={{ flexDirection: "column" }}
                >
                  <LoadingButton
                    // loading={load}
                    // className={styles.btn}
                    disabled={otpValue.length !== 6}
                    variant="contained"
                    sx={{ color: "#0D519A", borderColor: "#0D519A" }}
                    style={{ color: "#fff", width: 150, height: 40 }}
                    // type="submit"
                    // onClick={() => {
                    //   onLoginClick();
                    //   setLoad(true);
                    // }}
                    onClick={() => submitOtp()}
                  >
                    Continue
                  </LoadingButton>
                  <div>
                    {errmsg !== "" ? (
                      <div style={{ paddingTop: "15%", color: "#FF4343" }}>
                        {errmsg}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResetPasswordPage = ({ otpId, setScreen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordCon, setPasswordCon] = useState("");
  const [passwordTypeNew, setPasswordTypeNew] = useState(false);
  const [passwordTypeCon, setPasswordTypeCon] = useState(false);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [errmsg, setErrmsg] = useState("");

  const requestBody = {
    password: passwordCon,
    otpId: otpId,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };

  const resetPassword = () => {
    axios
      .post(`${baseUrl}/oauth/set/password`, requestBody, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.successful === true) {
            console.log(response);
            console.log("Success");
            setScreen(0);
            navigate("/");
          } else {
            setErrmsg(response.data.message);
          }
          // Redux
        } else {
          console.log(response.data.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Failed", error.response);
        setErrmsg(error.response.data.message);
        setLoad(false);
      });
  };
  return (
    <div className={styles.main}>
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "50%"}
              height={matches ? "60%" : "50%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 14,
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {matches ? "" : "Claims Approver Dashboard"}
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "40%" : "40%",
                width: matches ? "35%" : "50%",
              }}
            />
          </div>
        </div>
        {matches ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <img src={ForgotPassword} style={{ height: "70%", width: "80%" }} />
          </div>
        )}
        <div className={styles.subcon}>
          {matches ? (
            <div className={styles.leftcon}>
              <div>
                {/* <div className={styles.gettxt}>
                  Claims Approver
                  <br />
                  Dashboard
                </div> */}
              </div>
              <div className={styles.imgcon}>
                <img
                  src={ForgotPassword}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "25%" }}></div>
          )}
          <div className={styles.rightcon}>
            <div className={styles.shadow}>
              <div className={styles.logincon}>
                <div
                  style={{ color: "#0B5298", fontSize: 30, fontWeight: 600 }}
                >
                  Reset Password
                </div>
              </div>

              <div className={styles.midrcon}>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="New Password"
                    value={passwordNew}
                    onChange={(e) => setPasswordNew(e.target.value)}
                    // error={Boolean(errors.password)}
                    // helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordTypeNew === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordTypeNew(!passwordTypeNew)}
                          >
                            {passwordTypeNew === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Confirm Password"
                    value={passwordCon}
                    onChange={(e) => setPasswordCon(e.target.value)}
                    // error={Boolean(errors.password)}
                    // helperText={errors.password}
                    inputProps={{
                      style: {
                        height: "15px",
                        width: "230px",
                      },
                    }}
                    type={passwordTypeCon === false ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="text-28"
                            color="action"
                            onClick={() => setPasswordTypeCon(!passwordTypeCon)}
                          >
                            {passwordTypeCon === false ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  className={styles.btncon}
                  style={{ flexDirection: "column" }}
                >
                  <LoadingButton
                    // loading={load}
                    // className={styles.btn}
                    disabled={
                      passwordCon !== passwordNew || passwordCon.length === 0
                    }
                    variant="contained"
                    sx={{ color: "#0D519A", borderColor: "#0D519A" }}
                    style={{ color: "#fff", width: 150, height: 40 }}
                    // type="submit"
                    // onClick={() => {
                    //   onLoginClick();
                    //   setLoad(true);
                    // }}
                    onClick={() => resetPassword()}
                  >
                    Save Changes
                  </LoadingButton>
                  <div>
                    {errmsg !== "" ? (
                      <div style={{ paddingTop: "15%", color: "#FF4343" }}>
                        {errmsg}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
