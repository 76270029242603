import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postSetPassword = createAsyncThunk(
  "api/postSetPassword",
  async ({ url, requestBody, config }) => {
    const response = await axios.post(url, requestBody, config);
    return response.data;
  }
);

const postSetPasswordSlice = createSlice({
  name: "postSetPassword",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postSetPassword.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the success case if needed
      })
      .addCase(postSetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const postSetPasswordReducer = postSetPasswordSlice.reducer;
