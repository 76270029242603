import React, { useState, useEffect } from "react";
import styles from "../Css/claim.module.css";
import Ncash_logo from "../Images/Ncash_logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { Button, TextField } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { setAccessToken, setLoginResponse } from "../Redux/authSlice";
import { fetchClaimDetails } from "../Redux/apiGetSlice";
import { updateClaimStatus } from "../Redux/apiPutSlice";
const baseUrl = process.env.REACT_APP_API_PATH;
const ClaimsPage = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.fetchClaimDetails.data);
  // const loading = useSelector((state) => state.fetchClaimDetails.loading);
  // const error = useSelector((state) => state.fetchClaimDetails.error);
  // if (error) {
  //   console.log(error);
  // }

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [claimData, setClaimData] = useState();
  const [claimStatusRes, setClaimStatusRes] = useState();
  const [custRemark, setCustRemark] = useState("");
  const [apprRemark, setApprRemark] = useState("");
  const [apprAmount, setApprAmount] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [navbar, setNavbar] = useState("");

  const urls = [
    "https://ncaish-detax-bills-uat.s3.amazonaws.com/bc44a828-65a3-4a97-8a1c-4548c229d46b-upload_1679306070566.jpg",
    "https://ncaish-detax-bills-uat.s3.amazonaws.com/f619c305-36dc-45c6-9a3d-9394cfd710ee-IMG_20230313_201046.jpg",
    "https://ncaish-detax-bills-uat.s3.amazonaws.com/85c67ad7-a16b-4299-910d-e2dda90d52dd-upload_1678682765192.jpg",
  ];

  //Params & Header
  const requestBody = {
    remarks: apprRemark,
    amount: parseInt(apprAmount),
    approved: false,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "client-id": process.env.REACT_APP_CLIENT_ID,
      "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    },
  };

  //Claim Details GET Api
  useEffect(() => {
    // dispatch(
    //   fetchClaimDetails({
    //     url: `${baseUrl}/secure/detax/card/claims/${params?.claimId}`,
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //       "client-id": process.env.REACT_APP_CLIENT_ID,
    //       "client-secret": process.env.REACT_APP_CLIENT_SECRET,
    //     },
    //   })
    // );
    // setClaimData(data.data ?? "");
    // setApprAmount(data.data.appAmount.toString() ?? "");
    // setCustRemark(data.data.remarks ?? "");
    // setApprRemark(data.data.approverRemarks ?? "");
    axios
      .get(`${baseUrl}/secure/detax/card/claims/${params?.claimId}`, config)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success");
          setClaimData(response.data.data);
          setApprAmount(response.data.data.appAmount.toString());
          setCustRemark(response.data.data.remarks);
          // setApprRemark(response.data.data.approverRemarks);
        } else {
          console.log(response.status);
        }
      })
      .catch((error) => {
        console.log("Failed", error);
      });
  }, [params?.id, claimStatusRes]);

  //Toast Message
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    response: false,
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (success, open) => {
    setState({ ...state, open: open, response: success });
  };

  //Approve or Reject PUT Api
  const Submit = (status) => {
    // let body = { ...requestBody, approved: status };
    // dispatch(
    //   updateClaimStatus({
    //     url: `${baseUrl}/secure/detax/card/claims/approval/${params?.claimId}/approver`,
    //     body,
    //     config,
    //   })
    // );
    axios
      .put(
        `${baseUrl}/secure/detax/card/claims/approval/${params?.claimId}/approver`,
        { ...requestBody, approved: status },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Success");
          setClaimStatusRes(response.data.data);
          handleClick(response?.data?.successful, true);
          setTimeout(() => {
            setState({ ...state, open: false });
            handleGoBack();
          }, 2000);
        } else {
          console.log(response.status);
        }
      })
      .catch((error) => {
        console.log("Failed", error);
      });
  };

  //Breadcrumbs Callback Function
  const handleGoBack = () => {
    window.history.back();
  };
  const images = (claimData?.images ?? []).map((url) => ({
    original: url,
    thumbnail: url,
  }));
  return (
    <div className={styles.main}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={1000}
        open={open}
        message={
          claimData?.claimApproverStatus === "APPROVED"
            ? `${claimData?.claimCode} - Approved`
            : `${claimData?.claimCode} - Rejected`
        }
        key={vertical + horizontal}
      />
      <div className={styles.submain}>
        <div className={styles.header}>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              //  paddingRight: "30%",
            }}
          >
            <Ncash_logo
              width={matches ? "20%" : "70%"}
              height={matches ? "80%" : "70%"}
            />
          </div>
          <div
            className={styles.detaxtxt}
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: matches ? 30 : 17,
              fontWeight: "500",
            }}
          >
            Claims Approval
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              src={require("../Images/Figital_logo.png")}
              style={{
                height: matches ? "60%" : "50%",
                width: matches ? "20%" : "50%",
              }}
            />
          </div>
        </div>
        <div className={styles.namecon}>
          <div
            role="presentation"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb" separator={"›"}>
              {/* <Link underline="hover" color="inherit" href="/">
                MUI
              </Link> */}
              <Link
                underline="hover"
                color="inherit"
                onClick={() => handleGoBack()}
                // href="/home"
              >
                Home
              </Link>
              <Typography color="text.primary">
                {claimData?.claimCode}
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            <Button
              endIcon={<LogoutIcon />}
              sx={{
                color: "#0D519A",
                fontStyle: "bold",
                fontSize: "15px",
              }}
              onClick={() => {
                // navigate("/");
                dispatch(setAccessToken(null));
              }}
              // onClick={() => handleClick("Successful", true)}
            >
              {matches ? "Logout" : ""}
            </Button>
          </div>
        </div>

        <div className={styles.infocon} style={{ fontSize: matches ? 19 : 16 }}>
          {claimData?.initiator.name} has raised a claim of Rs.
          {claimData?.amount} and it{" "}
          {claimData?.claimApproverStatus === "APPROVER_PENDING"
            ? "is pending for your approval."
            : claimData?.claimApproverStatus === "APPROVED"
            ? "has been approved."
            : "has been rejected."}
        </div>

        {matches ? null : (
          <div style={{ height: "15%", width: "40%" }}>
            <ImageGallery
              items={images}
              showPlayButton={false}
              showNav={false}
              showIndex={true}
            />
          </div>
        )}
        {matches ? null : (
          <div
            style={{
              fontSize: 18,
              fontWeight: "600",
              paddingTop: "1%",
              display: "flex",
              // alignSelf: "flex-start",
              flexDirection: "column",
              width: "90%",
              lineHeight: 2,
            }}
          >
            Employee Details:
            <div style={{ fontWeight: "normal", fontSize: 16 }}>
              {claimData?.initiator.name} - {claimData?.initiator.mobile} /
              {claimData?.initiator.email}
            </div>
            Claim Details:
          </div>
        )}
        <div className={styles.midcon}>
          {matches ? (
            <div className={styles.midlcon}>
              <div className={styles.employee}>
                <u>Employee Details</u>
              </div>
              <div className={styles.details}>
                {claimData?.initiator.name}
                <br />
                {claimData?.initiator.mobile}
                <br />
                {claimData?.initiator.email}
              </div>
            </div>
          ) : null}
          <div className={styles.midrcon}>
            <table className={styles.table1}>
              <tbody>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>Claim Id</td>
                  <td className={styles.td2}>{claimData?.claimCode}</td>
                </tr>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>Date & Time</td>
                  <td className={styles.td2}>{claimData?.initiatedAt}</td>
                </tr>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>Category</td>
                  <td className={styles.td2}>{claimData?.category}</td>
                </tr>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>Amount Raised</td>
                  <td className={styles.td2}>₹ {claimData?.amount}</td>
                </tr>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>Approval Amount</td>
                  <td className={styles.td2}>
                    <TextField
                      type="text"
                      disabled={
                        claimData?.claimApproverStatus !== "APPROVER_PENDING"
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "numeric",
                        },
                        inputComponent: "input",
                      }}
                      rows={3}
                      value={apprAmount}
                      onChange={(e) => setApprAmount(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          // padding: 0,
                          // // paddingLeft: 1,
                          // // paddingRight: 1,
                        },
                        width: 200,
                      }}
                    />
                  </td>
                </tr>

                <tr className={styles.tr1}>
                  <td className={styles.td1}>
                    Customer
                    <br />
                    Remarks
                  </td>
                  <td className={styles.td2}>
                    <TextField
                      multiline
                      disabled={true}
                      rows={3}
                      value={custRemark}
                      onChange={(e) => setCustRemark(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": {
                          // height: 50,
                          padding: 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        },
                        width: 200,
                      }}
                    />
                  </td>
                </tr>
                <tr className={styles.tr1}>
                  <td className={styles.td1}>
                    Approver
                    <br />
                    Remarks
                  </td>
                  <td className={styles.td2}>
                    <TextField
                      disabled={
                        claimData?.claimApproverStatus !== "APPROVER_PENDING"
                      }
                      multiline
                      rows={3}
                      value={apprRemark}
                      onChange={(e) => setApprRemark(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": {
                          // height: 50,
                          padding: 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        },
                        width: 200,
                      }}
                    />
                  </td>
                </tr>
                {claimData?.claimApproverStatus ===
                "APPROVER_PENDING" ? null : (
                  <tr className={styles.tr1}>
                    <td className={styles.td1}>Approver Status</td>
                    <td className={styles.td2}>
                      {claimData?.claimApproverStatus === "APPROVED"
                        ? "Approved"
                        : "Rejected"}
                    </td>
                  </tr>
                )}
                {/* {claimData?.claimApproverStatus ===
                "APPROVER_PENDING" ? null : (
                  <tr className={styles.tr1}>
                    <td className={styles.td1}>Final Claim Status</td>
                    <td className={styles.td2}>
                      {claimData?.status === "REJECTED"
                        ? "Rejected"
                        : claimData?.status === "REVIEW_PENDING"
                        ? "Pending"
                        : claimData?.status}
                    </td>
                  </tr>
                )} */}
              </tbody>
            </table>
            {matches ? (
              <div style={{ height: "8%", width: "30%", paddingLeft: "8%" }}>
                <ImageGallery
                  items={images}
                  showPlayButton={false}
                  showNav={false}
                  showIndex={true}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={styles.footer}
          style={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            lineHeight: 3,
            justifyContent: matches ? "flex-end" : null,
            alignItems: matches ? null : "center",
          }}
        >
          {/* {value === 1 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#0D519A",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              Claim has been Approved.
            </div>
          ) : null} */}
          {/* {claimStatusRes?.status ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#0D519A",
                fontSize: 20,
                fontWeight: "500",
              }}
            >
              {claimStatusRes?.status === "REVIEW_PENDING"
                ? " Claim has been Approved."
                : "Claim has been Rejected."}
            </div>
          ) : null} */}
          {claimData?.claimApproverStatus === "APPROVER_PENDING" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className={styles.btn}>
                <Button
                  disabled={apprRemark.length === 0 || apprAmount.length === 0}
                  variant="outlined"
                  sx={{
                    color: "#0D519A",
                    borderColor: "#0D519A",
                    // backgroundColor: "#0D519A",
                  }}
                  onClick={() => {
                    // setValue(2);
                    Submit(false);
                  }}
                >
                  Reject
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  disabled={apprRemark.length === 0 || apprAmount.length === 0}
                  variant="contained"
                  sx={{
                    // color: "#0D519A",
                    borderColor: "#0D519A",
                    backgroundColor: "#0D519A",
                  }}
                  onClick={() => {
                    // setValue(1);
                    Submit(true);
                  }}
                >
                  Approve
                </Button>
              </div>
            </div>
          ) : null}
          <div className={styles.btn}>
            {/* <Button
              variant="contained"
              sx={{
                // color: "#0D519A",
                borderColor: "#0D519A",
                backgroundColor: "#0D519A",
              }}
              onClick={() => {
                handleNextClaim();
              }}
              // disabled={next <= user.length - 2 ? false : true}
            >
              View Next Claim
            </Button> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 10,
            fontWeight: "700",
            justifyContent: "center",
            paddingTop: matches ? "2%" : "10%",
            paddingBottom: matches ? "2%" : "5%",
          }}
        >
          Powered by Figital Technologies
        </div>
      </div>
    </div>
  );
};

export default ClaimsPage;

const DummyData = {
  name: "John Doe",
  amount: 2000,
  date: "23/03/2023",
  mobile: 9876543210,
  email: "a@a.com",
  claimId: "CLM-2849",
  category: "Food",
};
