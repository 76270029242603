import * as React from "react";
const Ncash_logo = (props) => (
  <svg
    width={259}
    height={392}
    viewBox="0 0 259 392"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_458_4)">
      <path
        d="M47.77 357.71L9.19 310.25V357.71H0V293.07H6.43L45 340.54V293.07H54.2V357.71H47.77Z"
        fill="#192027"
      />
      <path
        d="M100 342.66L106.71 347.55C104.788 350.698 102.014 353.239 98.7101 354.88C95.2649 356.735 91.4127 357.704 87.5001 357.7C84.4562 357.793 81.425 357.268 78.5893 356.158C75.7535 355.048 73.1719 353.375 71.0001 351.24C68.8472 349.145 67.1519 346.626 66.0211 343.843C64.8903 341.06 64.3486 338.073 64.4301 335.07C64.3473 332.081 64.8748 329.106 65.9803 326.328C67.0859 323.549 68.7463 321.025 70.8601 318.91C72.952 316.788 75.4583 315.119 78.223 314.008C80.9876 312.897 83.9515 312.366 86.9301 312.45C90.5366 312.442 94.0902 313.317 97.2801 315C100.453 316.629 103.195 318.986 105.28 321.88L98.8501 327.33C97.5735 325.32 95.8095 323.666 93.7219 322.52C91.6344 321.375 89.2911 320.776 86.9101 320.78C85.0792 320.735 83.259 321.073 81.5663 321.772C79.8735 322.471 78.3455 323.516 77.0801 324.84C75.7699 326.199 74.746 327.808 74.0692 329.57C73.3924 331.333 73.0765 333.213 73.1401 335.1C73.0593 337.006 73.3729 338.908 74.0612 340.687C74.7494 342.467 75.7976 344.085 77.1401 345.44C78.4969 346.766 80.108 347.804 81.8763 348.491C83.6447 349.179 85.5338 349.501 87.4301 349.44C89.9219 349.434 92.3735 348.812 94.5667 347.629C96.7598 346.446 98.6263 344.739 100 342.66Z"
        fill="#192027"
      />
      <path
        d="M156.24 301.8C156.858 301.805 157.449 302.053 157.885 302.491C158.321 302.929 158.567 303.522 158.57 304.14C158.581 304.44 158.527 304.74 158.411 305.018C158.295 305.296 158.121 305.545 157.9 305.75C157.684 305.969 157.425 306.142 157.14 306.258C156.854 306.373 156.548 306.428 156.24 306.42C155.94 306.426 155.642 306.372 155.363 306.261C155.085 306.149 154.832 305.982 154.62 305.77C154.407 305.558 154.24 305.305 154.129 305.026C154.017 304.748 153.963 304.45 153.97 304.15C153.959 303.84 154.013 303.531 154.128 303.244C154.244 302.956 154.418 302.696 154.64 302.48C154.837 302.255 155.082 302.077 155.358 301.959C155.633 301.841 155.931 301.787 156.23 301.8M156.23 299.87C155.67 299.858 155.113 299.964 154.596 300.181C154.079 300.398 153.614 300.721 153.23 301.13C152.839 301.524 152.531 301.993 152.323 302.508C152.115 303.023 152.012 303.574 152.02 304.13C152.012 304.682 152.114 305.231 152.321 305.743C152.528 306.256 152.835 306.722 153.225 307.114C153.614 307.506 154.078 307.816 154.589 308.027C155.1 308.237 155.647 308.344 156.2 308.34C156.756 308.348 157.309 308.245 157.824 308.035C158.34 307.826 158.808 307.514 159.2 307.12C159.607 306.735 159.928 306.269 160.143 305.752C160.359 305.236 160.463 304.679 160.45 304.12C160.453 302.986 160.006 301.898 159.207 301.094C158.408 300.29 157.323 299.835 156.19 299.83L156.23 299.87Z"
        fill="#192027"
      />
      <path
        d="M171.4 351.7L175.91 345.24C179.491 348.249 183.994 349.943 188.67 350.04C190.543 350.082 192.385 349.551 193.95 348.52C194.635 348.118 195.203 347.543 195.597 346.854C195.992 346.165 196.2 345.384 196.2 344.59C196.217 343.717 195.962 342.861 195.47 342.14C194.891 341.365 194.114 340.76 193.22 340.39C192.268 339.949 191.285 339.578 190.28 339.28C189.34 339.01 188.08 338.67 186.51 338.28C184.94 337.89 183.68 337.53 182.7 337.22C176.093 335.013 172.787 331.29 172.78 326.05C172.683 324.11 173.069 322.177 173.903 320.423C174.738 318.669 175.994 317.149 177.56 316C180.811 313.641 184.744 312.408 188.76 312.49C193.955 312.341 199.033 314.042 203.09 317.29L198.59 323.57C195.962 321.431 192.697 320.228 189.31 320.15C187.352 320.088 185.418 320.603 183.75 321.63C183.054 322.038 182.479 322.623 182.084 323.326C181.688 324.028 181.487 324.824 181.5 325.63C181.485 326.267 181.655 326.895 181.991 327.436C182.327 327.978 182.813 328.41 183.39 328.68C185.252 329.564 187.208 330.235 189.22 330.68C191.054 331.159 192.863 331.73 194.64 332.39C196.332 333.061 197.962 333.881 199.51 334.84C201.161 335.813 202.537 337.189 203.51 338.84C204.477 340.539 204.971 342.466 204.94 344.42C204.971 346.279 204.557 348.119 203.732 349.785C202.908 351.452 201.697 352.897 200.2 354C196.949 356.49 192.944 357.792 188.85 357.69C182.506 357.83 176.32 355.707 171.4 351.7Z"
        fill="#192027"
      />
      <path
        d="M225.91 293.07V317.2C227.879 315.521 230.16 314.246 232.623 313.449C235.085 312.652 237.68 312.348 240.26 312.554C242.84 312.76 245.354 313.473 247.658 314.651C249.962 315.829 252.012 317.449 253.69 319.42L254.29 320.16C257.1 323.73 258.59 328.73 258.59 335.44V357.71H249.86V335C250.127 331.487 249.197 327.986 247.22 325.07C246.185 323.699 244.834 322.598 243.283 321.86C241.732 321.122 240.026 320.769 238.31 320.83C235.904 320.821 233.53 321.376 231.377 322.449C229.224 323.522 227.351 325.084 225.91 327.01L225.98 357.72H217.25L217.18 293.07H225.91Z"
        fill="#192027"
      />
      <path
        d="M152.05 312.46V317.36C148.312 314.111 143.501 312.365 138.55 312.46C134.847 312.405 131.199 313.363 128 315.23C124.628 317.208 121.84 320.044 119.92 323.45C117.945 327.003 116.909 331 116.909 335.065C116.909 339.129 117.945 343.127 119.92 346.68C121.813 350.066 124.574 352.886 127.92 354.85C131.196 356.787 134.944 357.78 138.75 357.72C143.654 357.853 148.421 356.099 152.07 352.82V357.71H160.52V312.46H152.05ZM151.78 343.21C150.467 345.138 148.699 346.711 146.631 347.791C144.564 348.871 142.262 349.424 139.93 349.4C138.023 349.451 136.127 349.104 134.362 348.381C132.597 347.658 131.002 346.574 129.68 345.2C128.358 343.873 127.316 342.295 126.615 340.558C125.915 338.822 125.569 336.962 125.6 335.09C125.528 331.342 126.928 327.716 129.5 324.99C130.765 323.609 132.314 322.518 134.04 321.792C135.766 321.065 137.628 320.72 139.5 320.78C141.898 320.74 144.27 321.285 146.41 322.369C148.55 323.453 150.393 325.043 151.78 327V343.21ZM157.56 355.66H155V314.51H157.56V355.66Z"
        fill="#192027"
      />
      <path
        d="M63.4 59.21C62.2763 60.8519 60.8592 62.2724 59.22 63.4L195.22 199.4C196.342 197.756 197.764 196.338 199.41 195.22L63.4 59.21Z"
        fill="#0E5199"
      />
      <path
        d="M208.25 230C203.96 229.992 199.768 228.713 196.204 226.325C192.64 223.936 189.863 220.545 188.225 216.58C186.587 212.614 186.16 208.252 186.999 204.045C187.838 199.837 189.905 195.973 192.939 192.939C195.973 189.905 199.837 187.838 204.045 186.999C208.252 186.16 212.614 186.587 216.58 188.225C220.545 189.863 223.936 192.64 226.325 196.204C228.713 199.768 229.992 203.96 230 208.25C230.007 211.108 229.449 213.939 228.358 216.581C227.267 219.223 225.665 221.623 223.644 223.644C221.623 225.665 219.223 227.267 216.581 228.358C213.939 229.449 211.108 230.007 208.25 230ZM208.25 192.5C205.128 192.502 202.076 193.43 199.48 195.166C196.885 196.902 194.863 199.369 193.669 202.254C192.476 205.14 192.164 208.314 192.775 211.377C193.385 214.439 194.89 217.252 197.098 219.459C199.307 221.666 202.121 223.169 205.183 223.777C208.246 224.386 211.42 224.073 214.305 222.877C217.19 221.682 219.655 219.658 221.39 217.061C223.124 214.465 224.05 211.413 224.05 208.29C224.05 206.216 223.641 204.162 222.847 202.245C222.053 200.329 220.889 198.588 219.422 197.121C217.954 195.655 216.213 194.492 214.296 193.699C212.379 192.906 210.324 192.499 208.25 192.5Z"
        fill="#0E5199"
      />
      <path
        d="M50.3401 72.0499C46.0439 72.0519 41.8436 70.7797 38.2704 68.3942C34.6973 66.0088 31.9118 62.6173 30.2663 58.6487C28.6209 54.68 28.1893 50.3126 29.0262 46.0986C29.8632 41.8847 31.931 38.0136 34.9682 34.9751C38.0054 31.9365 41.8755 29.8668 46.0891 29.0279C50.3026 28.1891 54.6703 28.6186 58.6397 30.2623C62.6091 31.9059 66.0019 34.6898 68.3889 38.2618C70.776 41.8339 72.0501 46.0336 72.0501 50.3299C72.0448 56.087 69.7562 61.607 65.6862 65.6789C61.6162 69.7507 56.0973 72.0419 50.3401 72.0499ZM50.3401 34.5399C47.2143 34.5379 44.1582 35.4631 41.5583 37.1985C38.9585 38.9338 36.9318 41.4014 35.7347 44.2889C34.5376 47.1763 34.2239 50.354 34.8332 53.4198C35.4426 56.4857 36.9476 59.3019 39.1578 61.5122C41.3681 63.7224 44.1843 65.2274 47.2501 65.8368C50.316 66.4461 53.4937 66.1324 56.3811 64.9352C59.2686 63.7381 61.7361 61.7115 63.4715 59.1116C65.2069 56.5118 66.1321 53.4557 66.1301 50.3299C66.1301 46.1421 64.4665 42.1259 61.5053 39.1647C58.5441 36.2035 54.5279 34.5399 50.3401 34.5399Z"
        fill="#0E5199"
      />
      <path
        d="M225.27 0H160.88V5.92H222.82L252.67 35.77V222.77L222.82 252.62H162.12L63.8801 153.18C62.8409 154.898 61.4833 156.401 59.8801 157.61L159.59 258.61H225.22L258.54 225.29V33.29L225.27 0Z"
        fill="#0E5199"
      />
      <path
        d="M50.3401 166.8C46.0435 166.802 41.8427 165.529 38.2694 163.144C34.696 160.758 31.9105 157.366 30.2654 153.396C28.6202 149.427 28.1892 145.059 29.027 140.845C29.8647 136.631 31.9336 132.76 34.9718 129.722C38.01 126.683 41.881 124.614 46.0952 123.777C50.3094 122.939 54.6774 123.37 58.6466 125.015C62.6158 126.66 66.0079 129.446 68.3938 133.019C70.7797 136.592 72.0521 140.793 72.0501 145.09C72.0475 150.847 69.7593 156.367 65.6885 160.438C61.6176 164.509 56.0972 166.797 50.3401 166.8ZM50.3401 129.3C47.2171 129.3 44.1643 130.226 41.5677 131.961C38.971 133.696 36.9472 136.162 35.7521 139.047C34.5569 141.933 34.2443 145.107 34.8535 148.17C35.4628 151.233 36.9666 154.047 39.1749 156.255C41.3832 158.463 44.1967 159.967 47.2596 160.576C50.3226 161.186 53.4974 160.873 56.3827 159.678C59.2679 158.483 61.734 156.459 63.469 153.862C65.204 151.266 66.1301 148.213 66.1301 145.09C66.1301 140.902 64.4665 136.886 61.5053 133.925C58.5441 130.963 54.5279 129.3 50.3401 129.3Z"
        fill="#0E5199"
      />
      <path
        d="M5.93 222.81V35.81L35.78 5.92H96.49L195.2 104.63C196.319 102.981 197.741 101.559 199.39 100.44L98.94 0H33.32L0 33.32V225.26L33.31 258.58H97.71V252.66H35.78L5.93 222.81Z"
        fill="#0E5199"
      />
      <path
        d="M208.25 135.21C203.958 135.202 199.765 133.922 196.2 131.532C192.635 129.141 189.858 125.748 188.221 121.78C186.584 117.813 186.16 113.449 187.002 109.24C187.844 105.031 189.915 101.167 192.953 98.1346C195.991 95.1024 199.859 93.0387 204.069 92.2043C208.28 91.3698 212.643 91.8021 216.607 93.4464C220.572 95.0908 223.96 97.8734 226.344 101.443C228.728 105.012 230 109.208 230 113.5C230.001 116.355 229.439 119.182 228.346 121.819C227.253 124.456 225.651 126.852 223.63 128.868C221.61 130.885 219.211 132.483 216.572 133.572C213.933 134.66 211.105 135.217 208.25 135.21ZM208.25 97.71C205.128 97.712 202.076 98.6397 199.48 100.376C196.885 102.112 194.863 104.579 193.669 107.464C192.476 110.35 192.164 113.524 192.775 116.587C193.385 119.649 194.89 122.461 197.098 124.669C199.307 126.876 202.121 128.379 205.183 128.987C208.246 129.596 211.42 129.283 214.305 128.087C217.19 126.892 219.655 124.868 221.39 122.271C223.124 119.675 224.05 116.623 224.05 113.5C224.05 111.426 223.641 109.372 222.847 107.455C222.053 105.539 220.889 103.798 219.422 102.331C217.954 100.865 216.213 99.702 214.296 98.9091C212.379 98.1162 210.324 97.7087 208.25 97.71Z"
        fill="#0E5199"
      />
      <path d="M0 370.26H1.26V387.26H0V370.26Z" fill="#192027" />
      <path
        d="M5.39997 381.09C5.32392 379.451 5.90156 377.849 7.00597 376.636C8.11038 375.423 9.65123 374.698 11.29 374.62H11.41C12.4417 374.626 13.4539 374.902 14.3466 375.419C15.2393 375.936 15.9815 376.678 16.5 377.57V369.57H17.69V387.25H16.5V384.49C15.99 385.398 15.2526 386.157 14.3601 386.694C13.4677 387.23 12.451 387.525 11.41 387.55C10.6049 387.533 9.81107 387.358 9.0738 387.034C8.33652 386.71 7.67026 386.244 7.11307 385.663C6.55588 385.082 6.11869 384.396 5.82647 383.646C5.53425 382.895 5.39272 382.095 5.40997 381.29L5.39997 381.09ZM16.57 381.09V381C16.5913 380.323 16.4787 379.649 16.2387 379.016C15.9987 378.383 15.6359 377.803 15.1713 377.311C14.7067 376.818 14.1494 376.422 13.5314 376.146C12.9133 375.87 12.2468 375.718 11.57 375.7C8.88997 375.7 6.72997 377.7 6.72997 380.98V381C6.64171 382.314 7.07528 383.609 7.93653 384.605C8.79778 385.601 10.0172 386.217 11.33 386.32H11.57C12.9334 386.278 14.2247 385.698 15.1617 384.707C16.0986 383.715 16.605 382.393 16.57 381.03V381.09Z"
        fill="#192027"
      />
      <path
        d="M21.3499 381.07V381C21.3499 377.41 23.8699 374.56 27.2199 374.56C30.5699 374.56 32.8899 377.37 32.8899 381.03C32.8998 381.19 32.8998 381.35 32.8899 381.51H22.6099C22.6177 382.796 23.1306 384.027 24.0379 384.938C24.9452 385.849 26.1742 386.367 27.4599 386.38C28.2653 386.385 29.062 386.214 29.7944 385.879C30.5267 385.544 31.1771 385.053 31.6999 384.44L32.5199 385.17C31.897 385.916 31.1144 386.513 30.23 386.916C29.3455 387.32 28.3818 387.519 27.4099 387.5C25.7814 387.476 24.2285 386.809 23.091 385.643C21.9535 384.477 21.3239 382.909 21.3399 381.28C21.3474 381.21 21.3507 381.14 21.3499 381.07ZM31.5999 380.46C31.4299 377.97 29.9999 375.67 27.1699 375.67C24.7199 375.67 22.8299 377.73 22.6099 380.46H31.5999Z"
        fill="#192027"
      />
      <path
        d="M35.3401 383.64V383.59C35.3401 381.02 37.5401 379.59 40.7401 379.59C42.1217 379.571 43.499 379.749 44.8301 380.12V379.59C44.8301 377.09 43.3101 375.81 40.7601 375.81C39.4079 375.823 38.0794 376.166 36.8901 376.81L36.4501 375.81C37.8096 375.125 39.3077 374.759 40.8301 374.74C41.538 374.695 42.2477 374.793 42.9175 375.026C43.5872 375.26 44.2035 375.625 44.7301 376.1C45.1619 376.555 45.497 377.092 45.7153 377.68C45.9336 378.268 46.0305 378.894 46.0001 379.52V387.22H44.8301V385.16C44.2949 385.907 43.585 386.512 42.7622 386.922C41.9394 387.331 41.0289 387.533 40.1101 387.51C37.8101 387.52 35.3401 386.21 35.3401 383.64ZM44.8601 382.64V381.23C43.4932 380.865 42.085 380.677 40.6701 380.67C38.0501 380.67 36.6001 381.84 36.6001 383.56C36.6001 385.36 38.2901 386.4 40.1801 386.4C42.7001 386.4 44.8601 384.85 44.8601 382.6V382.64Z"
        fill="#192027"
      />
      <path
        d="M49.2 385.67L49.9 384.73C51.1582 385.749 52.7208 386.32 54.34 386.35C56.01 386.35 57.34 385.43 57.34 384C57.34 382.55 55.79 382 54.06 381.53C52.06 380.95 49.83 380.35 49.83 378.14C49.83 376.14 51.5 374.73 53.9 374.73C55.4429 374.741 56.9523 375.181 58.2599 376L57.63 377C56.5058 376.237 55.1877 375.811 53.83 375.77C52.13 375.77 51.02 376.69 51.02 377.92V377.97C51.02 379.35 52.6899 379.86 54.4599 380.37C56.4599 380.93 58.5299 381.66 58.5299 383.84V383.89C58.5299 386.09 56.6399 387.49 54.2599 387.49C52.4201 387.45 50.6434 386.811 49.2 385.67Z"
        fill="#192027"
      />
      <path
        d="M68.64 390.64L69.05 389.64C69.7024 389.971 70.4289 390.13 71.16 390.1C72.44 390.1 73.29 389.35 74.16 387.19L68.22 374.91H69.6L74.74 385.96L79.2 374.91H80.5L75.2 387.66C74.11 390.25 72.85 391.17 71.08 391.17C70.2369 391.182 69.4023 391.001 68.64 390.64Z"
        fill="#192027"
      />
      <path
        d="M82.2601 381.09C82.2468 380.252 82.3992 379.419 82.7084 378.64C83.0176 377.861 83.4776 377.15 84.0619 376.549C84.6463 375.948 85.3435 375.468 86.1137 375.137C86.8839 374.806 87.7118 374.63 88.5501 374.62H88.6301C89.4604 374.612 90.2841 374.768 91.054 375.079C91.8239 375.39 92.5248 375.85 93.1166 376.433C93.7084 377.015 94.1794 377.709 94.5026 378.474C94.8258 379.238 94.9948 380.06 95.0001 380.89V381.05C95.012 381.887 94.8586 382.719 94.5488 383.497C94.239 384.275 93.7789 384.984 93.1946 385.584C92.6104 386.184 91.9136 386.663 91.1441 386.994C90.3746 387.324 89.5475 387.499 88.7101 387.51H88.6301C86.9539 387.51 85.3464 386.844 84.1612 385.659C82.9759 384.474 82.3101 382.866 82.3101 381.19L82.2601 381.09ZM93.6701 381.09C93.6941 380.41 93.5838 379.731 93.3455 379.093C93.1072 378.456 92.7455 377.871 92.2811 377.374C91.8167 376.876 91.2588 376.474 90.6392 376.192C90.0196 375.91 89.3504 375.753 88.6701 375.73H88.5801C87.2353 375.754 85.9546 376.309 85.0177 377.274C84.0809 378.239 83.564 379.535 83.5801 380.88V381.09C83.5342 382.462 84.0351 383.797 84.9727 384.8C85.9102 385.803 87.2078 386.393 88.5801 386.44H88.6701C90.014 386.414 91.2931 385.858 92.2295 384.893C93.1658 383.929 93.6835 382.634 93.6701 381.29V381.13V381.09Z"
        fill="#192027"
      />
      <path
        d="M98.3701 382.43V374.87H99.5601V382.21C99.5601 384.75 100.99 386.43 103.49 386.43C104.046 386.438 104.599 386.336 105.116 386.13C105.633 385.924 106.104 385.618 106.502 385.23C106.901 384.842 107.219 384.379 107.438 383.867C107.657 383.356 107.774 382.806 107.78 382.25V374.86H109V387.22H107.82V384.92C107.396 385.728 106.753 386.4 105.964 386.858C105.175 387.317 104.272 387.543 103.36 387.51C102.74 387.549 102.118 387.465 101.53 387.263C100.942 387.061 100.4 386.744 99.9355 386.331C99.4707 385.918 99.0922 385.418 98.8218 384.858C98.5514 384.298 98.3945 383.691 98.3601 383.07C98.3501 382.85 98.3601 382.64 98.3701 382.43Z"
        fill="#192027"
      />
      <path
        d="M119.89 381.09C119.874 379.399 120.527 377.769 121.707 376.557C122.887 375.345 124.499 374.649 126.19 374.62C127.128 374.619 128.056 374.822 128.909 375.214C129.761 375.606 130.519 376.178 131.13 376.89L130.29 377.77C129.784 377.164 129.155 376.672 128.445 376.327C127.735 375.982 126.959 375.792 126.17 375.77C125.499 375.784 124.837 375.931 124.223 376.201C123.608 376.471 123.053 376.859 122.589 377.344C122.124 377.829 121.76 378.4 121.517 379.026C121.273 379.652 121.155 380.319 121.17 380.99C121.165 381.02 121.165 381.05 121.17 381.08V381.13C121.127 382.502 121.629 383.835 122.566 384.837C123.503 385.84 124.799 386.431 126.17 386.48H126.23C127.024 386.467 127.805 386.282 128.52 385.936C129.235 385.591 129.866 385.094 130.37 384.48L131.2 385.2C130.383 386.249 129.257 387.014 127.981 387.388C126.705 387.761 125.344 387.724 124.09 387.282C122.836 386.84 121.753 386.015 120.994 384.923C120.235 383.832 119.838 382.529 119.86 381.2L119.89 381.09Z"
        fill="#192027"
      />
      <path
        d="M133.56 383.64V383.59C133.56 381.02 135.76 379.59 138.97 379.59C140.352 379.571 141.729 379.749 143.06 380.12V379.59C143.06 377.09 141.54 375.81 138.99 375.81C137.638 375.823 136.309 376.167 135.12 376.81L134.68 375.81C136.04 375.125 137.538 374.759 139.06 374.74C139.768 374.676 140.481 374.755 141.158 374.972C141.834 375.188 142.461 375.538 143 376C143.437 376.465 143.774 377.015 143.991 377.615C144.207 378.215 144.299 378.853 144.26 379.49V387.19H143.1V385.13C142.564 385.879 141.852 386.485 141.028 386.894C140.203 387.304 139.291 387.505 138.37 387.48C136 387.52 133.56 386.21 133.56 383.64ZM143.09 382.64V381.23C141.72 380.864 140.308 380.676 138.89 380.67C136.28 380.67 134.82 381.84 134.82 383.56C134.82 385.36 136.52 386.4 138.41 386.4C140.93 386.4 143.09 384.85 143.09 382.6V382.64Z"
        fill="#192027"
      />
      <path
        d="M148.49 374.87H149.68V377.16C150.105 376.353 150.749 375.682 151.538 375.224C152.327 374.766 153.228 374.54 154.14 374.57C154.761 374.531 155.384 374.615 155.972 374.818C156.561 375.021 157.103 375.338 157.568 375.752C158.033 376.166 158.411 376.668 158.681 377.229C158.951 377.79 159.107 378.398 159.14 379.02C159.155 379.233 159.155 379.447 159.14 379.66V387.22H157.9V379.88C157.9 377.33 156.47 375.66 153.97 375.66C153.414 375.652 152.861 375.754 152.344 375.96C151.827 376.166 151.356 376.472 150.958 376.86C150.559 377.248 150.241 377.711 150.022 378.223C149.803 378.734 149.686 379.284 149.68 379.84V387.22H148.49V374.87Z"
        fill="#192027"
      />
      <path
        d="M169.77 381.09C169.754 379.399 170.407 377.769 171.587 376.558C172.767 375.346 174.379 374.649 176.07 374.62C177.018 374.614 177.956 374.813 178.82 375.205C179.683 375.597 180.451 376.172 181.07 376.89L180.22 377.77C179.714 377.164 179.085 376.672 178.375 376.327C177.665 375.982 176.889 375.793 176.1 375.77C175.429 375.785 174.767 375.931 174.153 376.201C173.538 376.471 172.983 376.86 172.519 377.344C172.054 377.829 171.69 378.401 171.447 379.026C171.203 379.652 171.085 380.319 171.1 380.99C171.1 380.99 171.1 381.05 171.1 381.08V381.13C171.057 382.502 171.559 383.835 172.496 384.837C173.433 385.84 174.729 386.431 176.1 386.48H176.16C176.958 386.456 177.741 386.259 178.456 385.904C179.17 385.548 179.8 385.042 180.3 384.42L181.12 385.15C180.303 386.199 179.177 386.964 177.901 387.338C176.625 387.711 175.264 387.674 174.01 387.232C172.757 386.79 171.674 385.965 170.914 384.873C170.155 383.782 169.758 382.48 169.78 381.15L169.77 381.09Z"
        fill="#192027"
      />
      <path
        d="M183.44 383.64V383.59C183.44 381.02 185.65 379.59 188.84 379.59C190.225 379.57 191.606 379.749 192.94 380.12V379.59C192.94 377.09 191.41 375.81 188.87 375.81C187.515 375.823 186.183 376.166 184.99 376.81L184.55 375.81C185.912 375.123 187.414 374.757 188.94 374.74C189.648 374.696 190.357 374.793 191.027 375.027C191.697 375.26 192.313 375.625 192.84 376.1C193.277 376.565 193.614 377.114 193.831 377.715C194.047 378.315 194.139 378.953 194.1 379.59V387.29H192.94V385.23C192.404 385.979 191.692 386.584 190.868 386.994C190.043 387.404 189.13 387.605 188.21 387.58C185.91 387.52 183.44 386.21 183.44 383.64ZM192.96 382.64V381.23C191.593 380.863 190.185 380.675 188.77 380.67C186.15 380.67 184.7 381.84 184.7 383.56C184.7 385.36 186.4 386.4 188.29 386.4C190.81 386.4 192.96 384.85 192.96 382.6V382.64Z"
        fill="#192027"
      />
      <path
        d="M197.3 385.67L198.01 384.73C199.264 385.751 200.824 386.321 202.44 386.35C204.11 386.35 205.44 385.43 205.44 384C205.44 382.55 203.89 382 202.17 381.53C200.17 380.95 197.93 380.35 197.93 378.14C197.93 376.14 199.6 374.73 202 374.73C203.561 374.761 205.081 375.229 206.39 376.08L205.76 377.08C204.632 376.318 203.311 375.892 201.95 375.85C200.26 375.85 199.14 376.77 199.14 378V378.05C199.14 379.43 200.81 379.94 202.58 380.45C204.58 381.01 206.65 381.74 206.65 383.92V383.97C206.65 386.17 204.76 387.57 202.39 387.57C200.531 387.512 198.742 386.844 197.3 385.67Z"
        fill="#192027"
      />
      <path
        d="M210.42 369.54H211.6V377.16C212.025 376.353 212.669 375.683 213.458 375.225C214.247 374.767 215.148 374.54 216.06 374.57C217.302 374.505 218.519 374.934 219.445 375.764C220.371 376.595 220.93 377.758 221 379C221.02 379.213 221.02 379.427 221 379.64V387.2H219.82V379.86C219.82 377.31 218.39 375.64 215.89 375.64C215.334 375.632 214.781 375.734 214.264 375.94C213.747 376.146 213.276 376.452 212.878 376.84C212.479 377.228 212.161 377.691 211.942 378.203C211.723 378.714 211.606 379.264 211.6 379.82V387.2H210.41L210.42 369.54Z"
        fill="#192027"
      />
      <path
        d="M231.7 381.09C231.687 380.252 231.839 379.419 232.148 378.64C232.457 377.861 232.917 377.15 233.502 376.549C234.086 375.948 234.783 375.468 235.554 375.137C236.324 374.806 237.152 374.631 237.99 374.62H238.07C238.899 374.619 239.721 374.781 240.488 375.098C241.254 375.414 241.951 375.879 242.538 376.465C243.125 377.051 243.591 377.747 243.909 378.513C244.226 379.279 244.39 380.101 244.39 380.93V381.09C244.402 381.927 244.248 382.759 243.939 383.537C243.629 384.315 243.169 385.024 242.584 385.624C242 386.224 241.303 386.703 240.534 387.034C239.764 387.364 238.937 387.54 238.1 387.55H238C236.324 387.547 234.717 386.881 233.53 385.697C232.344 384.512 231.675 382.906 231.67 381.23C231.672 381.182 231.683 381.135 231.7 381.09ZM243.11 381.09C243.156 379.717 242.655 378.381 241.718 377.377C240.781 376.372 239.483 375.78 238.11 375.73H238C236.648 375.746 235.358 376.297 234.411 377.262C233.465 378.227 232.939 379.528 232.95 380.88C232.955 380.933 232.955 380.987 232.95 381.04V381.09C232.904 382.462 233.405 383.797 234.343 384.8C235.28 385.803 236.578 386.393 237.95 386.44H238.04C239.384 386.414 240.663 385.858 241.599 384.894C242.536 383.929 243.053 382.634 243.04 381.29V381.13L243.11 381.09Z"
        fill="#192027"
      />
      <path
        d="M248.06 374.87H249.24V377.16C249.666 376.353 250.309 375.683 251.098 375.225C251.887 374.767 252.789 374.54 253.7 374.57C254.317 374.536 254.935 374.625 255.519 374.83C256.102 375.036 256.639 375.354 257.099 375.768C257.558 376.181 257.932 376.681 258.198 377.239C258.465 377.797 258.618 378.402 258.65 379.02C258.67 379.233 258.67 379.447 258.65 379.66V387.22H257.47V379.88C257.47 377.33 256.04 375.66 253.54 375.66C252.984 375.652 252.431 375.754 251.914 375.96C251.398 376.166 250.926 376.472 250.528 376.86C250.129 377.248 249.811 377.711 249.592 378.223C249.373 378.734 249.257 379.284 249.25 379.84V387.22H248.07L248.06 374.87Z"
        fill="#192027"
      />
    </g>
    <defs>
      <clipPath id="clip0_458_4">
        <rect width={258.66} height={391.17} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Ncash_logo;
